<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card>
      <marquee-text class="pl-24" :repeat="20" :duration="50">
        <h3>{{notice}}</h3>
      </marquee-text>
    </vx-card>
    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="invoiceData">

      <div slot="header" class="flex flex-wrap-reverse items-center">

        <Datepicker
          format="dd MMMM yyyy"
          :language="languages['th']"
          :disabled-dates="disabledInvoiceDates"
          class="date-picker m-4"
          v-model="invoice_date"
          placeholder="ระบุวันที่"
        />

        <vs-button type="filled" color="primary" @click="openAddInvoiceConfirm()">
          กำหนดวันส่งเงิน
        </vs-button>
      </div>

      <template slot="thead">
        <vs-th>ลำดับที่</vs-th>
        <vs-th>วันที่ส่งเงิน</vs-th>
        <vs-th>สร้างเมื่อ</vs-th>
        <vs-th>แก้ไขเมื่อ</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="product-name font-medium">{{ indextr + 1 }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium">{{ tr.invoice_date | dateFormat }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium">{{ tr.createdAt | dateTimeFormat }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium">{{ tr.updatedAt | dateTimeFormat }}</p>
          </vs-td>

        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
  import moduleInvoiceDate from "@/store/invoice-date/moduleInvoiceDate.js";
  import Datepicker from "vuejs-datepicker";
  import moment from "moment";
  import * as lang from "vuejs-datepicker/dist/locale";

  export default {
    components: {
      Datepicker,
    },
      data() {
      return {
        itemsPerPage: 20,
        disabledInvoiceDates: {
          to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          from: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() - 20),
        },
        invoice_date: new Date(),
        language: 'th',
        languages: lang,
        noticeFile: 'http://188.166.177.25/notice.json',
        notice:'***ห้ามลืมเด็ดขาด ผู้ปฎิบัติงานจะต้องกำหนดรอบส่งเงินทุกวัน*** ***การรับชำระเงินผิดพลาด  ตัดยอดส่งเงินผิดจะต้องชดเงินตัวเองออกไปก่อนนะเพราะไม่สามารถแก้ไขได้*** ***การเพื่มหรือยกเลิกสมาชิกจะต้องทำหลังจากเปลี่ยนรอบบิลเท่านั่น***'
      }
    },
    computed: {
      invoiceData() {
        return this.$store.state.invoiceDate.items;
      }
    },
    methods: {
      async getInvoiceDate() {
        await this.$store.dispatch("invoiceDate/fetchDataListItems");
      },
      openAddInvoiceConfirm() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          title: 'คำเตือน',
          text: 'คุณต้องการเพิ่มรอบส่งเงิน ให้ตรวจสอบก่อนว่าวันนี้เคยกำหนดแล้วหรือไม่',
          acceptText: 'ต้องการ',
          cancelText: 'ยกเลิก',
          accept: this.confirmAddInvoiceDate
        })
      },
      async confirmAddInvoiceDate() {
        this.$vs.loading();
        let date = { invoice_date: moment(this.invoice_date).format('YYYY/MM/DD') };
        await this.$store.dispatch("invoiceDate/addItem", date).then((res) => {
          if (res.status === 201) {
            this.getInvoiceDate();
            this.successDialog();
          } else {
            this.failDialog();
          }
          this.$vs.loading.close();
        }).catch((error) => {
          this.failDialog();
          this.$vs.loading.close();
        });
      },
      failDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: `ผิดพลาด`,
          text: 'เพิ่มรอบส่งเงินไม่สำเร็จ',
          acceptText: 'ปิด'
        })
      },
      successDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'success',
          title: `สำเร็จ`,
          text: 'เพิ่มรอบส่งเงินสำเร็จ',
          acceptText: 'ปิด'
        })
      }
    },
    async created() {
      this.$vs.loading();
      if (!moduleInvoiceDate.isRegistered) {
        this.$store.registerModule('invoiceDate', moduleInvoiceDate)
        moduleInvoiceDate.isRegistered = true
      }
      await this.$store.dispatch("invoiceDate/fetchDataListItems")
      this.$vs.loading.close();
    }
  }
</script>

<style lang="scss">
  #data-list-list-view {
    .vs-con-table {

      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search{
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            &+i {
              left: 1rem;
            }

            &:focus+i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
            justify-content: left;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: left;
      }
    }
  }
</style>
